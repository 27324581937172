import { Link } from "gatsby";
import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import Header from "../../../../src/components/Header";
import SectionWrapper from "../../../../src/components/sectionWrapper";
import OneColWrapper from "../../../../src/components/oneColWrapper";
import Image from "../../../../src/components/image";
import Text from "../../../../src/components/text";
import React from 'react';
export default {
  Link,
  Layout,
  SEO,
  Header,
  SectionWrapper,
  OneColWrapper,
  Image,
  Text,
  React
};
import React from "react"
import PropTypes from "prop-types"

import "./caption.css"

const Caption = ({ children }) => <div className="caption">{children}</div>

Caption.propTypes = {
  children: PropTypes.node,
}

Caption.defaultProps = {
  children: ``,
}

export default Caption

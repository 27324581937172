import Content from "../../../../src/content.json";
import { Link } from "gatsby";
import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import Header from "../../../../src/components/Header";
import SectionWrapper from "../../../../src/components/sectionWrapper";
import OneColWrapper from "../../../../src/components/oneColWrapper";
import TwoColWrapper from "../../../../src/components/twoColWrapper";
import ImageWithCaption from "../../../../src/components/imageWithCaption";
import Image from "../../../../src/components/image";
import Text from "../../../../src/components/text";
import Caption from "../../../../src/components/caption";
import React from 'react';
export default {
  Content,
  Link,
  Layout,
  SEO,
  Header,
  SectionWrapper,
  OneColWrapper,
  TwoColWrapper,
  ImageWithCaption,
  Image,
  Text,
  Caption,
  React
};
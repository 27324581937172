import React from "react"
import PropTypes from "prop-types"
import "./image.css"

const Image = ({ name, type, alt }) => {
  const img = require("../images/" + name + "." + type)
  if (type !== "svg") {
    const img2x = require("../images/" + name + "@2x." + type)
    return <img src={img} srcSet={img2x + " 2x"} alt={alt}></img>
  } else {
    return <img src={img} alt={alt}></img>
  }
}

Image.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  alt: PropTypes.string,
}

Image.defaultProps = {
  type: "jpg",
  alt:
    "Here we have probably forgotten an Image Description, please let us know.",
}

export default Image

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./layout.css"

import Image from "../components/image"

import TwoColWrapper from "./twoColWrapper"
import Text from "./text"

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <main>{children}</main>
        <footer>
          <div className="footerContent">
            <TwoColWrapper>
              <Link to="/">
                <Image name="Logo-Black" type="svg" />
              </Link>
              <a
                href="https://imd.mediencampus.h-da.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image name="IMD" type="svg" />
              </a>
              <Text>
                <Link to="/impressum">Impressum</Link>
                <Link to="/privacy">Privacy</Link>
              </Text>
            </TwoColWrapper>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-mdx": () => import("../src/pages/impressum.mdx" /* webpackChunkName: "component---src-pages-impressum-mdx" */),
  "component---src-pages-index-mdx": () => import("../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-privacy-mdx": () => import("../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */)
}


import React from "react"
import PropTypes from "prop-types"

import "./text.css"

const Text = ({ children }) => <div className="text">{children}</div>

Text.propTypes = {
  children: PropTypes.node,
}

Text.defaultProps = {
  children: ``,
}

export default Text

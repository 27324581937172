import React from "react"
import PropTypes from "prop-types"

import "./oneColWrapper.css"

const OneColWrapper = ({ children, mode }) => (
  <div className={"oneColWrapper " + mode}>{children}</div>
)

OneColWrapper.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
}

OneColWrapper.defaultProps = {
  children: ``,
  mode: ``,
}

export default OneColWrapper

import React from "react"
import PropTypes from "prop-types"

import "./imageWithCaption.css"

const ImageWithCaption = ({ children, mode }) => (
  <div className={`imageWithCaption ` + mode} r>
    {children}
  </div>
)

ImageWithCaption.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
}

ImageWithCaption.defaultProps = {
  children: ``,
  mode: ``,
}

export default ImageWithCaption
import React from "react"
import PropTypes from "prop-types"

import "./sectionWrapper.css"

const SectionWrapper = ({ children, bgcolor }) => (
  <div className="sectionWrapper" style={{ background: bgcolor }}>
    {children}
  </div>
)

SectionWrapper.propTypes = {
  children: PropTypes.node,
  bgcolor: PropTypes.string,
}

SectionWrapper.defaultProps = {
  children: ``,
  bgcolor: ``,
}

export default SectionWrapper

import React from "react"
import PropTypes from "prop-types"

import "./twoColWrapper.css"

const TwoColWrapper = ({ children }) => (
  <div className="twoColWrapper">
    <div></div>
    {children}
  </div>
)

TwoColWrapper.propTypes = {
  children: PropTypes.node,
}

TwoColWrapper.defaultProps = {
  children: ``,
}

export default TwoColWrapper

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Image from "../components/image"

import "./header.css"

const Header = ({ children, bgcolor }) => (
  <header style={{ background: bgcolor }}>
    <div className="headerContent">
      <div className="logo">
        <Link to="/">
          <Image name="Logo" type="svg" />
        </Link>
      </div>
      {children}
    </div>
  </header>
)

Header.propTypes = {
  children: PropTypes.node,
  bgcolor: PropTypes.string,
}

Header.defaultProps = {
  children: ``,
  bgcolor: ``,
}

export default Header
